<template>
  <div class="area">
    <van-popup v-model="show" round position="bottom" @click-overlay="onClose">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        round
        active-color="#1989fa"
        :options="options"
        @change="addressChange"
        :closeable="false"
        @close="onClose"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { api } from '@/api/index'

export default {
  props: {
    /**
     *是否支持无级选择
     */
    isNoLevel: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否显示地址选择器
     */
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      cascaderValue: '',
      levels1: [], //缓存市级
      levels2: [], //缓存县级
      levels3: [], //缓存乡级
      levels4: [], // 缓存村级
      options: [],
    }
  },
  mounted() {
    this.showArea()
  },
  methods: {
    showArea: function () {
      if (this.options.length < 1) {
        this.getCityList()
      }
    },
    onClose() {
      this.$emit('close')
      return false
    },
    //获取城市列表
    getCityList() {
      api.getCityList().then((res) => {
        // console.log("res1", res);
        if (this.isNoLevel) {
          this.options[0] = {
            text: '全省',
            value: 0,
          }
        }
        res.map((ct) => {
          this.options.push({
            text: ct.name,
            value: ct.id,
            children: [],
          })
        })
      })
    },
    //获取子区域地点
    async getChildArea(level, id) {
      // console.log(`${level}等级，id为${id}`);
      return new Promise((resolve, reject) => {
        api
          .getChildList({
            id: id,
          })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addressChange(value) {
      // 如果是第4层不用请求子区域
      if (value.tabIndex === 3) {
        return
      }
      if (value.value === 0) {
        console.log('请选择了全部', value)
        this.onFinish(value)
        return
      }
      this.getChildArea(value.tabIndex + 1, value.value).then((t) => {
        if (t.length > 0) {
          const levelIndex = value.tabIndex + 1 //等级索引
          this[`levels${levelIndex}`] = []
          let thisLevel = this[`levels${levelIndex}`]
          if (this.isNoLevel) {
            thisLevel = [
              {
                text: '全部',
                value: 0,
              },
            ]
          }
          t.map((i) => {
            if (value.tabIndex < 2) {
              thisLevel.push({
                text: i.name,
                value: i.id,
                children: [],
              })
            } else {
              thisLevel.push({
                text: i.name,
                value: i.id,
              })
            }
          })
          // console.log(value.tabIndex,this['levels'+value.tabIndex+1]);
          console.log({ thisLevel })
          value.selectedOptions[value.tabIndex].children = thisLevel
        } else {
          // 删除options数组中最后一组数据
          value.selectedOptions[value.tabIndex].children = [
            {
              text: value.selectedOptions[value.tabIndex].text,
              value: '',
            },
          ]
          Toast('暂无其他子区域')
        }
      })
    },
    onFinish({ selectedOptions }) {
      if (selectedOptions.length > 0) {
        if (selectedOptions[selectedOptions.length - 1].value === 0) {
          selectedOptions.pop()
        }
      }
      this.$emit('onFinish', selectedOptions)
    },
  },
}
</script>

<style lang="less" scoped>
.area {
  .area-name {
    position: relative;

    &::after {
      position: absolute;
      top: 12px;
      right: -10px;
      margin-top: -5px;
      border: 3px solid;
      border-color: transparent transparent #686b73 #686b73;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0.8;
      content: '';
    }
  }
}
</style>
