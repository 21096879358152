<!--description:-->
<!--"获取影片榜单"-->
<template>
  <div class="session-plan">
    <div class="session-plan__header header">
      <div class="header-name" @click="showArea">
        <span>{{ cityName | textLength }}</span>
      </div>
      <div class="header-name" @click="showDateSelect = true">
        <span>{{
          select.playStartDate === null
            ? '所有时间'
            : select.playStartDate + '至' + select.playEndDate
        }}</span>
      </div>
      <!--      <div style="visibility: hidden">-->
      <!--        <van-icon name="location" color="#6661ff" size="20" />-->
      <!--      </div>-->
    </div>
    <div>
      <!--      {{select}}-->
    </div>
    <div class="session-plan__content">
      <div class="session-plan-list" v-if="sessionList.length > 0">
        <div
          class="session-plan-list__item"
          v-for="session in sessionList"
          :key="session.id"
        >
          <div class="session-plan-list__item__header">
            <div class="session-plan-list__item__header__left"></div>
            <div class="session-plan-list__item__header__right">
              <p>{{ session.playDatetime }}</p>
              <p>
                {{ session.playPlace }}
                <!--                            | {{ session.distance }}-->
              </p>
            </div>
          </div>
          <div
            class="session-plan-list__item__content"
            @click="goFilmDetail(film)"
            v-for="(film, f) in session.movieList"
            :key="f"
          >
            <div class="session-plan-list__item__content__img">
              <img :src="film.filmPicture" alt="" />
            </div>
            <div class="session-plan-list__item__content__info">
              <div>{{ film.name }} ({{ film.year }})</div>
              <div style="margin: 6px auto">
                <van-rate
                  v-model="film.score"
                  :size="25"
                  readonly
                  allow-half
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                />
              </div>
              <p>{{ film.synopsis }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty :show="true" description="该地区暂无放映计划"></van-empty>
      </div>
    </div>
    <div>
      <!--        {{select}}-->
    </div>
    <select-area
      :show.sync="showAreaSelect"
      :is-no-level="true"
      @onFinish="onFinishAddress"
      @close="showAreaSelect = false"
    ></select-area>
    <!--      日期选择器-->
    <van-calendar
      v-model="showDateSelect"
      color="#1989fa"
      position="top"
      @confirm="onConfirmDate"
      :show-confirm="false"
      :first-day-of-week="1"
      type="range"
      ref="calendar"
      :default-date="null"
    >
      <template #footer>
        <van-button
          size="small"
          @click="clearFilterDate"
          block
          round
          style="margin-bottom: 5px"
          color="#1989fa"
          >还原默认
        </van-button>
      </template>
    </van-calendar>
  </div>
</template>

<script>
import selectArea from '@comp/areaPicker/areaSelect.vue'
import { api } from '@api'

export default {
  name: 'sessionPlan',
  data() {
    return {
      showAreaSelect: false,
      showDateSelect: false,
      pageNo: 1,
      pageSize: 99,
      selectArea: { text: '请选择', value: '', children: [] },
      cityName: '请选择',
      select: {
        address: [],
        playEndDate: null,
        playStartDate: null,
      },
      sessionList: [],
    }
  },
  components: {
    selectArea,
  },
  mounted() {
    const location = this.$store.state.userAddress
    this.select.address = location.join('')
    this.cityName = this.getLastItem(location) || '全省'
    this.initPage()
  },
  methods: {
    initPage() {
      this.getSessionsList()
    },
    showArea: function () {
      this.showAreaSelect = true
    },
    onFinishAddress(address) {
      this.select.address = address.map((item) => item.text).join('')
      this.cityName =
        address.length > 0 ? this.getLastItem(address).text : '全省'
      this.$store.commit(
        'changeUserAddress',
        address.map((item) => item.text),
      )
      this.showAreaSelect = false
      this.getSessionsList()
    },
    onConfirmDate(date) {
      console.log(date)
      this.showDateSelect = false
      const [start, end] = date
      console.log(start)
      console.log(end)
      this.select.playStartDate = `${start.getFullYear()}-${
        start.getMonth() + 1
      }-${start.getDate()}`
      this.select.playEndDate = `${end.getFullYear()}-${
        end.getMonth() + 1
      }-${end.getDate()}`
      this.getSessionsList()
    },
    clearFilterDate() {
      this.showDateSelect = false
      this.select.playStartDate = null
      this.select.playEndDate = null
      this.$refs.calendar.reset()
      this.getSessionsList()
    },
    getSessionsList() {
      // console.log(this.select);
      const place = this.select.address
      api.checkIn
        .getFilmBillboard({
          place,
          playStartDate: this.select.playStartDate,
          playEndDate: this.select.playEndDate,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        })
        .then((result) => {
          const { records } = result
          // console.log(result);
          this.sessionList = records
        })
    },
    // 跳转到电影详情
    goFilmDetail(film) {
      // filmDetail?id=1527491134953684993&title=绿茵之外&playType=1
      // console.log(film);
      this.$router.push({
        path: '/filmDetail',
        query: {
          id: film.id,
        },
      })
    },
    // 获取数组最后一项
    getLastItem(arr) {
      return arr[arr.length - 1]
    },
  },
  filters: {
    // 过滤器
    // 文字长度过滤
    textLength(text) {
      if (text.length > 5) {
        return text.slice(0, 4) + '...'
      } else {
        return text
      }
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}

.session-plan {
  min-height: 100vh;
  background: #f6f7f8;
  padding-bottom: 70px;

  &__header {
    height: 46px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      flex: 1;

      &:nth-child(3) {
        width: 30px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }

  &__content {
    width: 95%;
    margin: 10px auto;
    border-radius: @border-radius-base;
  }

  &-list {
    &__item {
      width: 98%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;

      &__header {
        height: 69px;
        background: #ffffff
          linear-gradient(180deg, #fef0cf 0%, rgba(255, 255, 255, 0.5) 100%);
        border-radius: @border-radius-base;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;

        p {
          margin: 0;
          padding: 5px;
        }

        &__left {
          width: 50px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: 60%;

          & > img {
            width: 30px;
          }
        }

        &__right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }

      &__content {
        background-color: #fff;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        //border: 1px solid red;

        &__img {
          flex: 1.2;

          height: 132px;
          border-radius: @border-radius-base;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__info {
          flex: 3;
          //border: 1px solid #000;
          font-size: 17px;
          font-family: PingFangSC-Semibold, PingFang SC, serif;
          font-weight: 600;
          color: #363a44;

          > p {
            height: 54px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #686b73;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}

.header {
  position: relative;

  &-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323233;
    &:first-child {
      flex: 1;
    }
    &:nth-child(2) {
      flex: 1.8;
    }

    span::after {
      position: absolute;
      top: 25px;
      margin-left: 5px;
      margin-top: -5px;
      border: 3px solid;
      border-color: transparent transparent #dcdee0 #dcdee0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0.8;
      content: ' ';
    }
  }
}
</style>
